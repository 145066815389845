var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "headerContainer__alignRight" },
          [
            _c("span", [_vm._v("Licenses Summary PDF")]),
            !_vm.licenseSummary
              ? [
                  _c("div", { staticClass: "btn btn--sm" }, [
                    _c("input", {
                      staticClass: "formUpload__inputHide",
                      attrs: { id: "pdf_upload", type: "file", accept: ".pdf" },
                      on: {
                        change: function ($event) {
                          return _vm.updateLicensePdf($event.target.files)
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "pdf_upload" } }, [
                      _vm._v(
                        _vm._s(!_vm.licenseSummary ? "UPLOAD" : "RE-UPLOAD")
                      ),
                    ]),
                  ]),
                ]
              : [
                  _c(
                    "span",
                    { staticClass: "icon-license-file" },
                    [
                      _c("icon", {
                        attrs: { name: "file", width: "30", height: "30" },
                      }),
                      _c("input", {
                        staticClass: "formUpload__inputHide",
                        attrs: {
                          id: "pdf_upload",
                          type: "file",
                          accept: ".pdf",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updateLicensePdf($event.target.files)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn--xs btn--black btn--border btnLicensePdf",
                      on: {
                        click: function ($event) {
                          return _vm.removeLicenseSummaryPdf()
                        },
                      },
                    },
                    [_vm._v("REMOVE")]
                  ),
                ],
          ],
          2
        ),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _vm.isLoading || _vm.licenseOptions.length === 0
        ? _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [_c("VLoadSpinner")],
            1
          )
        : _c(
            "div",
            { staticClass: "container container--fullWidth grid grid__col4" },
            [
              _vm._l(_vm.licenseOptions, function (licenseOption, index) {
                return _c("box-license", {
                  key: index,
                  attrs: { licenseOption: licenseOption, index: index },
                  on: {
                    callUpdateLicenseOption:
                      _vm.setUpdatedPropertyOnLicenseOption,
                    callRemoveLicenseOption: _vm.openConfirmActionModal,
                  },
                })
              }),
              _vm.licenseOptions.length <= 4
                ? _c(
                    "a",
                    {
                      staticClass:
                        "box box--borderDashed box--sm box--flexCenter",
                      on: { click: _vm.addBlankCoupon },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("icon", {
                            staticClass: "box__mainIcon",
                            attrs: { name: "add" },
                          }),
                          _c("h2", [_vm._v("Add License")]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
    ]),
    _c("section", { staticClass: "section__bottom-button" }, [
      _c(
        "a",
        {
          staticClass: "btn btn--primary",
          on: { click: _vm.saveLicenseOptions },
        },
        [_vm._v("SAVE CHANGES")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h1", [_vm._v("License Options")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }