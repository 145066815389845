var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", [
    _c(
      "div",
      {
        staticClass: "box box--filled box--sm box--minHeight h-textCenter",
        class: { "box--is-exclusive": _vm.licenseOption.is_exclusive },
      },
      [
        _vm.licenseOption.error
          ? _c(
              "h3",
              {
                staticClass:
                  "formInput__hint formInput__hint--error h-textCenter",
              },
              [_vm._v(_vm._s(_vm.licenseOption.error))]
            )
          : _vm._e(),
        _c("icon", {
          staticClass: "box__cornerIcon",
          attrs: { name: "trash" },
          nativeOn: {
            click: function ($event) {
              return _vm.removeLicenseOption.apply(null, arguments)
            },
          },
        }),
        _c("h4", { staticClass: "box__title" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.licenseOption.name,
                expression: "licenseOption.name",
              },
            ],
            staticClass: "box__input",
            class: { "box__input--empty": !_vm.licenseOption.name },
            attrs: { type: "text", placeholder: "Enter License Name" },
            domProps: { value: _vm.licenseOption.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.licenseOption, "name", $event.target.value)
              },
            },
          }),
        ]),
        _c(
          "div",
          { staticClass: "formGroup formGroup--horizontal h-alignCenter" },
          [
            _c("label", { staticClass: "formLabel formLabel--dark" }, [
              _vm._v("Exclusive License"),
            ]),
            _c("toggle-button", {
              attrs: { color: "#0269FF", labels: true },
              model: {
                value: _vm.licenseOption.is_exclusive,
                callback: function ($$v) {
                  _vm.$set(_vm.licenseOption, "is_exclusive", $$v)
                },
                expression: "licenseOption.is_exclusive",
              },
            }),
          ],
          1
        ),
        _c("hr", { staticClass: "form__divider" }),
        _c(
          "div",
          { staticClass: "formGroup formGroup--horizontal" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "Price",
                rules: { required: true, numeric: true },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var errors = ref.errors
                    return [
                      _c("div", { staticClass: "flex35" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Price USD"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.licenseOption.price,
                              expression: "licenseOption.price",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          class: { hasError: errors.length > 0 },
                          attrs: {
                            name: "price",
                            type: "text",
                            placeholder: "$",
                          },
                          domProps: { value: _vm.licenseOption.price },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.licenseOption,
                                "price",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "formInput__hint formInput__hint--error",
                          },
                          [_vm._v(_vm._s(errors[0]))]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("ValidationProvider", {
              attrs: {
                name: "Sale Units",
                rules: { required: true, numeric: true },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var errors = ref.errors
                    return [
                      _c("div", { staticClass: "flex65" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Sale Units"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.licenseOption.sale_units,
                              expression: "licenseOption.sale_units",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          class: { hasError: errors.length > 0 },
                          attrs: {
                            name: "sale units",
                            type: "text",
                            placeholder: "0 for unlimited",
                          },
                          domProps: { value: _vm.licenseOption.sale_units },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.licenseOption,
                                "sale_units",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "formInput__hint formInput__hint--error",
                          },
                          [_vm._v(_vm._s(errors[0]))]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("hr", { staticClass: "form__divider" }),
        _c(
          "div",
          { staticClass: "formGroup" },
          [
            _c("div", { staticStyle: { "margin-bottom": "8px" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.licenseOption.show_streams,
                    expression: "licenseOption.show_streams",
                  },
                ],
                staticClass: "formCheckbox",
                class: { checked: _vm.licenseOption.show_streams },
                attrs: { id: "streams-" + _vm.index, type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.licenseOption.show_streams)
                    ? _vm._i(_vm.licenseOption.show_streams, null) > -1
                    : _vm.licenseOption.show_streams,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.licenseOption.show_streams,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.licenseOption,
                            "show_streams",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.licenseOption,
                            "show_streams",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.licenseOption, "show_streams", $$c)
                    }
                  },
                },
              }),
              _c("label", { attrs: { for: "streams-" + _vm.index } }, [
                _vm._v("Add Streaming Restrictions"),
              ]),
            ]),
            _c("ValidationProvider", {
              attrs: { name: "Stream Units", rules: { numeric: true } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var errors = ref.errors
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.licenseOption.stream_units,
                            expression: "licenseOption.stream_units",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "formInput formInput--fullWidth",
                        class: {
                          hasError: errors.length > 0,
                          isDisabled: !_vm.licenseOption.show_streams,
                        },
                        attrs: {
                          name: "stream units",
                          type: "text",
                          placeholder: "0 for unlimited",
                        },
                        domProps: { value: _vm.licenseOption.stream_units },
                        on: {
                          click: function ($event) {
                            _vm.licenseOption.show_streams = true
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.licenseOption,
                              "stream_units",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c(
                        "p",
                        {
                          staticClass: "formInput__hint formInput__hint--error",
                        },
                        [_vm._v(_vm._s(errors[0]))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("hr", { staticClass: "form__divider" }),
        _c("div", { staticClass: "formGroup" }, [
          _c(
            "p",
            {
              staticClass: "formLabel",
              staticStyle: { "margin-bottom": "10px" },
            },
            [_vm._v("Downloadable Files")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.licenseOption.mp3,
                expression: "licenseOption.mp3",
              },
            ],
            staticClass: "formCheckbox",
            class: {
              checked: _vm.licenseOption.mp3,
              isDisabled: _vm.licenseOption.is_exclusive,
            },
            attrs: {
              id: "mp3-" + _vm.index,
              type: "checkbox",
              disabled: _vm.licenseOption.is_exclusive,
            },
            domProps: {
              checked: Array.isArray(_vm.licenseOption.mp3)
                ? _vm._i(_vm.licenseOption.mp3, null) > -1
                : _vm.licenseOption.mp3,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.licenseOption.mp3,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.licenseOption, "mp3", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.licenseOption,
                        "mp3",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.licenseOption, "mp3", $$c)
                }
              },
            },
          }),
          _c("label", { attrs: { for: "mp3-" + _vm.index } }, [_vm._v("MP3")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.licenseOption.wav,
                expression: "licenseOption.wav",
              },
            ],
            staticClass: "formCheckbox",
            class: {
              checked: _vm.licenseOption.wav,
              isDisabled: _vm.licenseOption.is_exclusive,
            },
            attrs: {
              id: "wav-" + _vm.index,
              type: "checkbox",
              disabled: _vm.licenseOption.is_exclusive,
            },
            domProps: {
              checked: Array.isArray(_vm.licenseOption.wav)
                ? _vm._i(_vm.licenseOption.wav, null) > -1
                : _vm.licenseOption.wav,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.licenseOption.wav,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.licenseOption, "wav", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.licenseOption,
                        "wav",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.licenseOption, "wav", $$c)
                }
              },
            },
          }),
          _c("label", { attrs: { for: "wav-" + _vm.index } }, [_vm._v("WAV")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.licenseOption.trackout,
                expression: "licenseOption.trackout",
              },
            ],
            staticClass: "formCheckbox",
            class: {
              checked: _vm.licenseOption.trackout,
              isDisabled: _vm.licenseOption.is_exclusive,
            },
            attrs: {
              id: "trackout-" + _vm.index,
              type: "checkbox",
              disabled: _vm.licenseOption.is_exclusive,
            },
            domProps: {
              checked: Array.isArray(_vm.licenseOption.trackout)
                ? _vm._i(_vm.licenseOption.trackout, null) > -1
                : _vm.licenseOption.trackout,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.licenseOption.trackout,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.licenseOption, "trackout", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.licenseOption,
                        "trackout",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.licenseOption, "trackout", $$c)
                }
              },
            },
          }),
          _c("label", { attrs: { for: "trackout-" + _vm.index } }, [
            _vm._v("Trackout"),
          ]),
        ]),
        _c("hr", { staticClass: "form__divider" }),
        _c("div", { staticClass: "formGroup" }, [
          _c(
            "label",
            {
              staticClass: "formLabel",
              staticStyle: { "margin-bottom": "10px" },
            },
            [_vm._v("More Details")]
          ),
          _c("div", { staticClass: "formGroup" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.licenseOption.intro_tag,
                  expression: "licenseOption.intro_tag",
                },
              ],
              staticClass: "formCheckbox",
              class: { checked: _vm.licenseOption.intro_tag },
              attrs: { id: "license-intro-tag" + _vm.index, type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.licenseOption.intro_tag)
                  ? _vm._i(_vm.licenseOption.intro_tag, null) > -1
                  : _vm.licenseOption.intro_tag,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.licenseOption.intro_tag,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.licenseOption,
                          "intro_tag",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.licenseOption,
                          "intro_tag",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.licenseOption, "intro_tag", $$c)
                  }
                },
              },
            }),
            _c("label", { attrs: { for: "license-intro-tag" + _vm.index } }, [
              _vm._v("Has Intro Tag"),
            ]),
          ]),
          _c("div", { staticClass: "formGroup" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.licenseOption.is_royalty_free,
                  expression: "licenseOption.is_royalty_free",
                },
              ],
              staticClass: "formCheckbox",
              class: { checked: _vm.licenseOption.is_royalty_free },
              attrs: {
                id: "license-royalty-free" + _vm.index,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.licenseOption.is_royalty_free)
                  ? _vm._i(_vm.licenseOption.is_royalty_free, null) > -1
                  : _vm.licenseOption.is_royalty_free,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.licenseOption.is_royalty_free,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.licenseOption,
                          "is_royalty_free",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.licenseOption,
                          "is_royalty_free",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.licenseOption, "is_royalty_free", $$c)
                  }
                },
              },
            }),
            _c(
              "label",
              { attrs: { for: "license-royalty-free" + _vm.index } },
              [_vm._v("Royalty Free")]
            ),
          ]),
          !_vm.licenseOption.is_exclusive
            ? _c("div", { staticClass: "formGroup" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.licenseOption.is_featured,
                      expression: "licenseOption.is_featured",
                    },
                  ],
                  staticClass: "formCheckbox",
                  class: { checked: _vm.licenseOption.is_featured },
                  attrs: {
                    id: "license-is-features" + _vm.index,
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.licenseOption.is_featured)
                      ? _vm._i(_vm.licenseOption.is_featured, null) > -1
                      : _vm.licenseOption.is_featured,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.licenseOption.is_featured,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.licenseOption,
                              "is_featured",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.licenseOption,
                              "is_featured",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.licenseOption, "is_featured", $$c)
                      }
                    },
                  },
                }),
                _c(
                  "label",
                  { attrs: { for: "license-is-features" + _vm.index } },
                  [_vm._v("Featured License")]
                ),
              ])
            : _vm._e(),
        ]),
        _c("hr", { staticClass: "form__divider" }),
        _c(
          "div",
          { staticClass: "formGroup formGroup--horizontal h-alignCenter" },
          [
            _c("label", { staticClass: "formLabel formLabel--dark" }, [
              _vm._v("Buy"),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.licenseOption.deal_buy,
                    expression: "licenseOption.deal_buy",
                  },
                ],
                staticClass: "formSelect",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.licenseOption,
                      "deal_buy",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { domProps: { value: 0 } }, [_vm._v("0")]),
                _vm._l(10, function (i) {
                  return _c("option", { domProps: { value: i } }, [
                    _vm._v(_vm._s(i)),
                  ])
                }),
              ],
              2
            ),
            _c("label", { staticClass: "formLabel formLabel--dark" }, [
              _vm._v("Get"),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.licenseOption.deal_get,
                    expression: "licenseOption.deal_get",
                  },
                ],
                staticClass: "formSelect",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.licenseOption,
                      "deal_get",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { domProps: { value: 0 } }, [_vm._v("0")]),
                _vm._l(10, function (i) {
                  return _c("option", { domProps: { value: i } }, [
                    _vm._v(_vm._s(i) + " "),
                  ])
                }),
              ],
              2
            ),
            _c("label", { staticClass: "formLabel formLabel--dark" }, [
              _vm._v("Free"),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }